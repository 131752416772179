<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("redeem_confirm_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('redeem_confirm_page.form_desc')"
    ></div>
    <!-- <div  class="page__content" v-if="getModuleConfig('redeem_confirm_page.content')" v-html="getModuleConfig('redeem_confirm_page.content')"></div> -->

    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <div v-else-if="!showLoading">
        <div
          v-html="getModuleConfig('redeem_confirm_page.content')"
          class="p-4 shadow-lg rounded redeem--content"
        ></div>
      </div>
    </div>

    <div
      v-if="
        getModuleConfig('redeem_confirm_page.button_text') ||
        getModuleConfig('redeem_confirm_page.cancel_button_text')
      "
      class="p-3 fixed-bottom bg-white footer"
    >
      <SharedButton class="s-btn-bg-primary mb-2"
      @click="$router.push({ name: 'LiffProjAbbottAndCouponRedeemSuccess' })">{{
        getModuleConfig("redeem_confirm_page.button_text")
      }}</SharedButton>
      <SharedButton variant="outline-dark" class="s-btn-outline-primary">{{
        getModuleConfig("redeem_confirm_page.cancel_button_text")
      }}</SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import couponMixin from "@/mixins/liff/coupon";

export default {
  mixins: [couponMixin],
  components: { Loading, SharedButton },
  data() {
    return {
      showLoading: false,
    };
  },

  computed: {},
  mounted() {},

  methods: {},
};
</script>

<style lang="scss">
.redeem {
  &--content {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
